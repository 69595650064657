<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 23:07:15
 * @LastEditTime: 2021-05-27 15:34:28
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="contact-us">
    <SonTitle title="联系我们"></SonTitle>
    <div class="contact">
      <p v-if="data.phone">电话: {{ data.phone }}</p>
      <p v-if="data.qq">在线QQ客服: {{ data.qq }}</p>
      <p v-if="data.wechat">官方微信: {{ data.wechat }}</p>
      <p v-if="data.weibo">新浪微博: {{ data.weibo }}</p>
      <p v-if="data.addr">地址:{{ data.addr }}</p>
      <img v-if="data.map_img" class="map" :src="data.map_img" alt="" />
      <!-- <div class="wx">
        <img src="../../assets/images/wx.png" alt="二维码" />
        <p>扫码关注微信</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: {}
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$api.getContactUs().then(res => {
        this.data = res.data.data
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.contact {
  position: relative;
  font-size: 16px;
  h3 {
    font-size: 20px;
  }
  h3,
  > p {
    margin-bottom: 20px;
  }
  .map {
    margin-top: 40px;
    width: 100%;
  }
  .wx {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 110px;
      height: 110px;
      display: block;
    }
  }
}
</style>